import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import NavigationPanel from '../navigation/NavigationPanel.jsx';

import AboutContainer from '../containers/AboutContainer';
import ExpertiseContainer from '../containers/ExpertiseContainer';
import Home from '../content/Home';
import Rates from '../content/Rates';

/**
 * Simple container for managing the whole page.
 * 
 * This is purely for simplicity so that if the layout of the tabs need to be changed,
 * it is easily done here.
 */
export default class ContentContainer extends Component {
    state = {
        items: []
    };

    componentDidMount() {
        const items = [
            { itemClass: "home", caption: "Home" },
            { itemClass: "about", caption: "About" },
            { itemClass: "expertise", caption: "Expertise" },
            { itemClass: "rates", caption: "Rates" }
        ];

        this.setState( {
            items: items
        } );
    }

    render() {
        return(
            <Fragment>
                <Router>
                    <NavigationPanel
                        navigationItems={this.state.items} />

                    <div className="content-boundary">
                        <Route exact path="/" render={() => <Redirect to="/home" />} />

                        <Route exact path="/about" component={AboutContainer} />
                        <Route exact path="/expertise" component={ExpertiseContainer} />
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/rates" component={Rates} />
                    </div>
                </Router>
            </Fragment>
        );
    }
};
