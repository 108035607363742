import React, { Component, Fragment } from 'react'

import './Content.css'
import './Home.css'

/**
 * Component that forms the body of the "Home" page.
 */
export default class Home extends Component {
    render() {
        return(
            <Fragment>
                <div className="logo"></div>

                <h3>About DFAW Software</h3>

                <p>An independant contractor (sole-trader), DFAW software provides consultancy and programming experties, primarily full stack Web development.</p>

                <h4>Contact</h4>

                <p>For all enquiries (quotations) and possible job offerings, please use the email address below:</p>
                <p>contact@dfaw.com.au</p>

                <h4>Address</h4>

                <p>PO Box 4286</p>
                <p>Geelong, VIC</p>
                <p>3220     Australia</p>

                <h4>Australian Business Number (ABN)</h4>

                <p>56 568 378 826</p>
            </Fragment>
        );
    }
};
