import React, { Component } from 'react';

import Expertise from '../content/Expertise';

import expertiseData from '../data/cur_expertise.json';
import skillsData from '../data/cur_skills.json';

const fetchData = () => {
    return new Promise( resolve => {
        resolve( {
            expertise: expertiseData,
            skills: skillsData
        } );
    } );
};

export default class ExpertiseContainer extends Component {
    state = {
        expertise: [],
        skills: []
    };

    componentDidMount() {
        fetchData().then(d => {
            this.setState( d );
        });
    }

    render() {
        return(
            <Expertise
                expertise={this.state.expertise}
                skills={this.state.skills} />
        );
    }
};
