import './App.css';

import ContentContainer from './containers/ContentContainer';

/**
 * Effectively, ReactJS's answer to a coding main module.
 * 
 * @returns The root most DOM of the whole site.
 */
function App() {
    return (
        <div className="App">
            <ContentContainer />
        </div>
    );
}

export default App;
