import './Content.css';
import './About.css';

import moment from 'moment';
import React, { Component, Fragment } from 'react';

/**
 * Convert an existing YYYY-MM-DD date string and return a formated one.
 * 
 * @param {string} strDate Original, unformatted date string.
 * 
 * @returns The same date but in D MMMM, YYYY format.
 */
const displayDate = ( strDate ) => {
    var m = moment( strDate, "YYYY-MM-DD" );

    var rtn = m.format( "D MMMM, YYYY" );

    return( rtn );
};

/**
 * Convert an existing YYYY-MM-DD date string and return a formated one.
 * 
 * Mainly for use on the education history section.
 * 
 * @param {string} strDate Original unformatted date string.
 * 
 * @returns The same date, but in MMMM, YYYY format.
 */
const displayMonth = ( strDate ) => {
    var m = moment( strDate, "YYYY-MM-DD" );

    var rtn = m.format( "MMMM, YYYY" );

    return( rtn );
};

/**
 * Simple component holding a previous employment entry.
 */
class EmploymentItem extends Component {
    render() {
        const { data } = this.props;

        return(
            <Fragment>
                <strong><em>{data.position}</em></strong><br />
                <strong>{data.employment}</strong><br />
                <em>{displayMonth(data.dateStart)} - {displayMonth(data.dateEnd)}</em>
                
                <ul>
                    {data.highlights.map(h => (
                        <li>{h}</li>
                    ))}
                </ul>
            </Fragment>
        );
    }
}

/**
 * Simple component holding a previous education entry.
 */
class EducationItem extends Component {
    render() {
        const { data } = this.props;

        let titleDom;

        if( data.title ) {
            titleDom = <Fragment>{data.title}<br /></Fragment>;
        }

        return(
            <li>
                <strong>{data.qualification}</strong><br />
                {titleDom}
                {data.institution}, {displayDate(data.date)}.
            </li>
        );
    }
}

/**
 * Component that forms the body of the "About" page.
 */
export default class About extends Component {
    render() {
        const {
            employmentHistory,
            educationHistory
        } = this.props;

        return(
            <Fragment>
                <div className="bio_pic"></div>

                <h3>Michael Brock, PhD</h3>
                    
                <p><em>Founder of DFAW Software</em></p>
                
                <h3>Biography</h3>
                
                <p>Michael is a expert programmer in .NET/Web and iOS environments, a cloud computing expert and former academic of Deakin University, Australia.  His thesis, entitled 'Enhancing Clouds, Internet-scale Distributed Systems, via Attributed Web Services', provides a new and innovative software framework easing the discovery, selection and use of various computing resources within cloud environments.</p>
                <p>During his time at Deakin, Michael delivered classes for computer networks and distributed systems, and helped develop the unit content.  He has also presented his research, conducted at Deakin, to a number of leading international conferences.  In 2010, he was awarded the Best Paper award for FutureTech 2010 for the publication, Grids vs. Clouds.</p>
                <p>He has also co-authored a number of journal articles (has appeared in the first issue of the International Journal of Cloud Computing), has co-authored numerous book chapters and has peer reviewed numerous conference articles.</p>
                <p>His company, DFAW Software, provides software development and consultation services and for over eight years provided extensive software engineering services under contract to Discus Analytics for their award winning JointManTM product.</p>
                
                <h3>Previous Employment</h3>
                
                {employmentHistory.map(emp => (
                    <EmploymentItem data={emp} />
                ))}
                
                <h3>Education</h3>
                
                <ul>
                    {educationHistory.map(edu => (
                        <EducationItem data={edu} />
                    ))}
                </ul>
            </Fragment>
        );
    }
};
