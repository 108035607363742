import './NavigationPanel.css';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

/**
 * Simple component that describes a navigation item/tab on the screen.
 */
class NavigationItem extends Component {
    render() {
        const {
            itemClass,
            caption
        } = this.props;

        return(
            <div className={"navigation-item " + itemClass}>
                <div className="navigation-item-icon"></div>
                <div className="navigation-item-caption">{caption}</div>
            </div>
        );
    }
}

/**
 * Small component to create the navigation panel on the top or side of the screen.
 * 
 * Requires a navigationItems property to list all panel items.
 * 
 * @param {array} props Properties given to the navigation panel by its parent.
 */
export default function NavigationPanel(props) {
    const {
        navigationItems
    } = props;

    return(
        <div className="navigation-panel">
            {navigationItems.map( ni => (
                <NavLink  key={ni.itemClass} exact to={"/" + ni.itemClass} activeClassName="selected">
                    <NavigationItem key={ni.itemClass}
                                    itemClass={ni.itemClass}
                                    caption={ni.caption} />
                </NavLink>
            ) )}

            <div className="logo-etching"></div>
        </div>
    );
};
