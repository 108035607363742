import './Content.css';
import './Expertise.css';

import React, { Component, Fragment } from 'react';

const displayTitle = (item) => {
    var rtn = item.title;

    if(item.subTitle) {
        rtn += (` (${item.subTitle})`);
    }

    return(rtn);
};

class ExpertiseItem extends Component {
    render() {
        const {
            expertise
        } = this.props;

        var caption = displayTitle(expertise);

        return(
            <li>{caption}</li>
        );
    }
}

class SkillItem extends Component {
    render() {
        const {
            skill
        } = this.props;

        return(
            <tr>
                <td className="skill_title">{displayTitle(skill)}</td>
                <td className="proficiency_col">
                    <div className={`proficiency_indicator level_${skill.proficiency}`}></div>
                </td>
            </tr>
        );
    }
}

/**
 * Component that forms the body of the "Expertise" page.
 */
export default class Expertise extends Component {
    render() {
        const {
            expertise,
            skills
        } = this.props;

        return(
            <Fragment>
                <h3>Expertise</h3>

                <ul>
                    {expertise.map( ex => (
                        <ExpertiseItem expertise={ex} />
                    ) )}
                </ul>

                <h3>Framework/Language Skills</h3>

                <table className="proficiency_table">
                    <thead>
                        <tr>
                            <th className="skill_title">Language/Technology</th>
                            <th>Proficiency</th>
                        </tr>
                    </thead>

                    <tbody>
                        {skills.map( sk => (
                            <SkillItem skill={sk} />
                        ) )}
                    </tbody>
                </table>
            </Fragment>
        );
    }
};
