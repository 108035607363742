import React, { Component, Fragment } from 'react'

import './Content.css'

/**
 * Componnet the forms the body of the "Rates" page.
 */
export default class Rates extends Component {
    render() {
        return(
            <Fragment>
                <h3>Rates</h3>

                <p>DFAW Software is based in Australia and all services rendered are charged at an hourly rate rounded down to the closest 15 minute block.</p>

                <p>The rate covers all services including consultion, software design and development, and any bug fixes carried out during the life of the client project.</p> 

                <p>All quotations for services rendered are free of charge and are obligation free.</p>

                <p>Please note that while quotations are free of charge and come with no obligations, they must be accepted within 14 days otherwise they are deemed void.</p>

                <p>Ongoing support is not included and incurs an additional charge.</p>

                <p>Acceptance of a quote also requires finalisation of project scope.  Any changes in scope can result in quotes being void and new estimates issued.</p>
            </Fragment>
        );
    }
};
