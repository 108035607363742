import React, { Component } from 'react';

import About from '../content/About';

import employmentHistoryData from '../data/his_employment.json';
import educationHistoryData from '../data/his_education.json';

/**
 * Attempt to fetch the current employment and education history.
 * 
 * @returns A promise that attempts to return the education and employment history.
 */
const fetchData = () => {
    return new Promise( resolve => {
        resolve( {
            educationHistory: educationHistoryData,
            employmentHistory: employmentHistoryData
        } );
    } );
}

/**
 * Simple container to hold the "About" page content.
 * 
 * In practice, this should be using a call to a WebAPI.  But this container
 * simply uses a imported JSON file with all the required data.
 */
export default class AboutContainer extends Component {
    state = {
        employmentHistory: [],
        educationHistory: []
    };

    componentDidMount() {
        fetchData().then(d => {
            this.setState( d );
        } );
    }

    render() {
        return(
            <About
                employmentHistory={this.state.employmentHistory}
                educationHistory={this.state.educationHistory} />
        );
    }
};
